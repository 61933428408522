
.footer{
  border-top: 1px solid #C9C9C9;
  padding:  40px 0;
  @include to(md){
    padding:  20px 0;
  }
}

.footer-menu{
  padding: 0;
  display: flex;
  margin-bottom: 16px;
  &__item{
    &:not(:last-child){
      margin-right: 30px;
      @include to(md){
        margin-right: 20px;
      }
    }
  }
  &__link{
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #000000 !important;
  }
}
.footer__about{
  font-size: 12px;
  line-height: 14px;
  color: #5C5C5C;
}