.SlimSelect{
  max-width: 224px;
  margin-bottom: 24px;
  .ss-single-selected {
    border-radius: 20px;
    height: 40px;
    padding-left: 24px;
    padding-right: 16px;
  }
  .placeholder{
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
  }
  .ss-main .ss-single-selected .placeholder .ss-disabled {
    color: #5C5C5C;
  }
  .ss-content.ss-open {
    border-top: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .ss-content .ss-list .ss-option.ss-highlighted, .ss-content .ss-list .ss-option:hover {
    color: $accent;
    background-color: transparent;
  }
  .ss-content .ss-option {
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    padding-left: 24px;
  }

}