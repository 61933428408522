.mb-64-30{
  margin-bottom: 64px;
  @include to(md){
    margin-bottom: 30px;
  }
}
.mb-60-30{
  margin-bottom: 60px;
  @include to(md){
    margin-bottom: 30px;
  }
}

.mb-30{
    margin-bottom: 30px;
}
.mt-40-mb-80-mt-30-mb-40{
  margin-bottom: 80px;
  @include to(md){
    margin-top: 15px;
    margin-bottom: 40px;
  }
}


.mb-40-20{
  margin-bottom: 40px;
  @include to(md){
    margin-bottom: 20px;
  }
}
.border-block{
  border-top: 1px solid #C9C9C9;
  padding-top: 64px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}