.header {
  position: sticky;
  top: 0em;
  left: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 999;
  transition: $trans;
  border-bottom: 1px solid #cccccc;
  &.bg {
    border-bottom: 1px solid #cccccc;
  }


  &-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0 23px;
    position: relative;
    height: 100px;
    @include to(md) {
      padding: 10px 0;
      height: 56px;
    }
  }

  &-logo {
    max-width: 166px;
    width: 100%;
    margin-right: auto;
    z-index: 9;

    @include to(md){
      max-width: 120px;
      top: 2px;
      margin-left: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 266px;
      padding-left: 10px;
    }
  }

  &-menu-top {
    @include to(lg) {
      display: none;
      position: fixed;
      top: 0;
      text-align: left;
      left: 0;
      background: #FFFFFF;
      height: 100%;
      z-index: 999;
      width: 266px;
      padding-top: 31px;
    }
    &.active {
      display: block;
      animation: fadeIn .3s ease-in-out;
    }
    &-list {
      display: flex;
      list-style: none;
      padding: 0;
      @include to(lg){
        flex-direction: column;
      }
      &__item {
        &:not(:last-child) {
          margin-right: 19px;
          @include to(lg) {
            margin-right: 0;
          }
        }
        @include to(lg) {
          margin-left: 14px;
          margin-bottom: 20px;
        }
      }

      &__link {
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
        padding: 45px 5px;
        transition: $trans;
        @include to(md) {
          line-height: 20px;
          padding: 0;
          text-transform: uppercase;
          color: #5C5C5C;
        }

        &:hover {
          color: $accent;
        }
      }
    }
  }

  &-menu-top {
    margin-right: 35px;
    position: relative;
    margin-top: 15px;
    @include to(md){
      margin-top: 0;
      margin-right: 0;
    }
    .footer-menu{
      display: none;
      @include to(lg){
        display: block;
      }
    }
    .trigger-close{
      display: none;
      position: absolute;
      right: 16px;
      top: 16px;
      @include to(lg){
        display: block;
      }
    }
  }

  &-bay {
    width: 24%;
    @include to(lg) {
      width: auto;
    }
  }
  &-search{
    @include to(md){
      margin-left: auto;
    }
  }
}

.header-bottom {
  background: #FFFFFF;
  box-shadow: 0px 12px 18px rgba(92, 92, 92, 0.16);
  padding: 30px 0;
  height: 75vh;
  position: absolute;
  left: 0;
  top: 101px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $trans;
  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.menu-bottom-list {
  overflow: auto;
  padding: 0;
}

.menu-bottom-list {
  width: 100%;

  &__item {
    width: 14.222%;
    margin-bottom: 30px;
  }

  &__link {
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    display: block;
    margin-bottom: 4px;
    transition: $trans;

    &:hover {
      color: $accent;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    text-transform: uppercase;
    color: #000000;
  }
}

.menu-bottom-list--big {
  width: 100%;
  margin-bottom: 80px;
    .menu-bottom-list__item {
      width: 23%;
      @include to(md){
        width: 50%;
      }
      margin-bottom: 30px;
    }
}

.search-block {
  position: relative;
  margin-right: 24px;

  @include to(sm){
    margin-right: 20px;
  }

  &:hover {
    .search-block__input {
      opacity: 1;
      width: 640px;
      @include to(md){
        width: 320px;
      }
      @include to(sm){
        width: 230px;
      }
    }
  }

  &__btn {
    border: none;
    background: transparent;
    position: relative;
    z-index: 2;
    padding-left: 0;
    padding-right: 0;
  }

  &__input {
    font-size: 15px;
    line-height: 22px;
    right: 0;
    color: #909090;
    transition: $trans;
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #909090;
    padding-right: 29px;
    opacity: 0;
    padding-bottom: 8px;
    width: 0;
    background: #FFFFFF;
    z-index: 9;
    &:focus, &:valid {
      opacity: 1;
      width: 640px;
      @include to(md){
        width: 320px;
      }
      @include to(sm){
        width: 278px;
        height: 55px;
        top: -13px;
        line-height: 43px;
      }
    }
  }
}

.bay-block {
  display: flex;
  align-items: center;

  &-action {
    position: relative;
    margin-right: 8px;

    &__some {
      font-size: 10px;
      line-height: 14px;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      justify-content: center;
      display: flex;
      align-items: center;
      background: $accent;
      color: #FFFFFF;
    }
  }

  &-cost {
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    margin-right: 15px;
    white-space: nowrap;
    @include  to(md){
      margin-right: 0;
    }
    @include to(sm){
      display: none;
    }
  }
}

.bay-block {
  .btn {
    @include to(md) {
      display: none;
    }
  }
}


.burger {
  position: relative;
  padding: 0;
  border: none;
  align-self: center;
  background: transparent;
  cursor: pointer;
  z-index: 999;
  flex-shrink: 0;
  width: 22px;
  height: 17px;
  @include from(lg) {
    display: none;
  }

  &.active {
    span:nth-child(1) {
      transform: translate(0, 3px) rotate(45deg);
    }

    span:nth-child(2) {
      width: 0;
    }

    span:nth-child(3) {
      transform: translate(0, -13px) rotate(-45deg);
    }
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: $trans;
    border-radius: 25px;
    background: #5C5C5C;

    &:nth-child(1) {
      top: 0;
      left: 0;
      bottom: 0;
    }

    &:nth-child(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      left: 0;
      bottom: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header{
  .footer-menu__link {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #5C5C5C !important;
  }
  .footer-menu__item{
    margin-right: 30px;
    @include to(lg){
      margin-bottom: 20px;
    }
  }
  .footer-menu{
    @include to(lg){
      margin-left: 14px;
    }
  }
}

.digits ul {
  background: transparent;
  position: relative;
}
.header__login{
  margin: 0 15px;
}
.header-search{
  margin-top: 7px;
  @include to(md){
    margin-top: 0;
  }
}

.bay-block {
  margin-top: 10px;
  @include to(md){
    margin-top: 0;
  }
}