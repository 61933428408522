.title{
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 48px;
  @include to(md){
    margin-bottom: 30px;
  }
}
.subtitle{
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #363636;
  margin-bottom: 30px;
}
.text{
  font-size: 14px;
  line-height: 24px;
  color: #5C5C5C;
  a{
    color: $accent;
  }
  h3{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #000000;
  }
}
.text-img{
  img{
    width: 100%;
    max-width: 100%;
    height: 340px;
    object-fit: cover;
    @include to(md){
      height: 300px;
    }
  }
}

.breadcrumbs{
  font-size: 10px;
  margin-bottom: 42px;
  margin-top: 24px;
  @include to(md){
    font-size: 11px;
    margin-bottom: 30px;
    margin-top: 15px;
  }
  a{
    font-size: 10px;
    line-height: 14px;
    color: #909090;
    &:hover{
      text-decoration: none;
    }
  }
  span{
    font-size: 10px;
    line-height: 14px;
    color: #000000;
  }
}
.back-link{
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #5C5C5C;
  display: inline-flex;
  align-items: center;
  &:hover{
    color: $accent;
  }
}

.text{
  table{
    width: 100% !important;
    border: 1px solid #ccc;
  }
  td {
    border: 1px solid #ccc;
  }
}
.bg-gray{
  background: #F9F9F9;
  padding-top: 64px;
  padding-bottom: 60px;
}

.seo_text {
  background: #F9F9F9;
  padding: 64px 0 40px;
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 48px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 48px;
  }

  a {
    color: $accent;
  }

}
.text{
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 48px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 26px;
    color: #000000;
    margin-bottom: 30px;
  }
  a {
    color: $accent;
  }
  p {
    margin-bottom: 15px;
  }
  ul{
    margin-bottom: 15px !important;
  }
  .t_news{
    margin-bottom: 15px;
  }

}

td img {
  @include to(md) {
    object-fit: contain;
  }
}
a.active{
  color: $accent !important;
}
