@charset "UTF-8";
@import url(/node_modules/bootstrap/dist/css/bootstrap-reboot.min.css);
@import url(/node_modules/bootstrap/dist/css/bootstrap-grid.min.css);
@import url(/node_modules/swiper/swiper-bundle.min.css);
@import url(/node_modules/slim-select/dist/slimselect.min.css);
@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  src: url("../font/Gilroy/Gilroy-Regular.eot");
  src: url("../font/Gilroy/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Gilroy/Gilroy-Regular.woff") format("woff"), url("../font/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  src: url("../font/Gilroy/Gilroy-Medium.eot");
  src: url("../font/Gilroy/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("../font/Gilroy/Gilroy-Medium.woff") format("woff"), url("../font/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  src: url("../font/Gilroy/Gilroy-SemiBold.eot");
  src: url("../font/Gilroy/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"), url("../font/Gilroy/Gilroy-SemiBold.woff") format("woff"), url("../font/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -15%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 48px;
}

@media (max-width: 767px) {
  .title {
    margin-bottom: 30px;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #363636;
  margin-bottom: 30px;
}

.text {
  font-size: 14px;
  line-height: 24px;
  color: #5C5C5C;
}

.text a {
  color: #EA0280;
}

.text h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #000000;
}

.text-img img {
  width: 100%;
  max-width: 100%;
  height: 340px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .text-img img {
    height: 300px;
  }
}

.breadcrumbs {
  font-size: 10px;
  margin-bottom: 42px;
  margin-top: 24px;
}

@media (max-width: 767px) {
  .breadcrumbs {
    font-size: 11px;
    margin-bottom: 30px;
    margin-top: 15px;
  }
}

.breadcrumbs a {
  font-size: 10px;
  line-height: 14px;
  color: #909090;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.breadcrumbs span {
  font-size: 10px;
  line-height: 14px;
  color: #000000;
}

.back-link {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #5C5C5C;
  display: inline-flex;
  align-items: center;
}

.back-link:hover {
  color: #EA0280;
}

.text table {
  width: 100% !important;
  border: 1px solid #ccc;
}

.text td {
  border: 1px solid #ccc;
}

.bg-gray {
  background: #F9F9F9;
  padding-top: 64px;
  padding-bottom: 60px;
}

.seo_text {
  background: #F9F9F9;
  padding: 64px 0 40px;
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
}

.seo_text h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 48px;
}

.seo_text h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 30px;
}

.seo_text p {
  margin-bottom: 48px;
}

.seo_text a {
  color: #EA0280;
}

.text h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 48px;
}

.text h2 {
  font-weight: 500;
  font-size: 20px !important;
  line-height: 26px;
  color: #000000;
  margin-bottom: 30px;
}

.text a {
  color: #EA0280;
}

.text p {
  margin-bottom: 15px;
}

.text ul {
  margin-bottom: 15px !important;
}

.text .t_news {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  td img {
    object-fit: contain;
  }
}

a.active {
  color: #EA0280 !important;
}

.mb-64-30 {
  margin-bottom: 64px;
}

@media (max-width: 767px) {
  .mb-64-30 {
    margin-bottom: 30px;
  }
}

.mb-60-30 {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .mb-60-30 {
    margin-bottom: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-40-mb-80-mt-30-mb-40 {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .mt-40-mb-80-mt-30-mb-40 {
    margin-top: 15px;
    margin-bottom: 40px;
  }
}

.mb-40-20 {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .mb-40-20 {
    margin-bottom: 20px;
  }
}

.border-block {
  border-top: 1px solid #C9C9C9;
  padding-top: 64px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  list-style: none;
}

a[class] {
  text-decoration: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button {
  outline: none;
}

html,
body {
  min-height: 100%;
}

html,
body {
  height: 100%;
  font-family: "Gilroy", sans-serif;
}

.container-fluid {
  max-width: 1175px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}

.header {
  position: sticky;
  top: 0em;
  left: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 999;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid #cccccc;
}

.header.bg {
  border-bottom: 1px solid #cccccc;
}

.header-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 23px;
  position: relative;
  height: 100px;
}

@media (max-width: 767px) {
  .header-row {
    padding: 10px 0;
    height: 56px;
  }
}

.header-logo {
  max-width: 166px;
  width: 100%;
  margin-right: auto;
  z-index: 9;
}

@media (max-width: 767px) {
  .header-logo {
    max-width: 120px;
    top: 2px;
    margin-left: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 266px;
    padding-left: 10px;
  }
}

@media (max-width: 991px) {
  .header-menu-top {
    display: none;
    position: fixed;
    top: 0;
    text-align: left;
    left: 0;
    background: #FFFFFF;
    height: 100%;
    z-index: 999;
    width: 266px;
    padding-top: 31px;
  }
}

.header-menu-top.active {
  display: block;
  animation: fadeIn .3s ease-in-out;
}

.header-menu-top-list {
  display: flex;
  list-style: none;
  padding: 0;
}

@media (max-width: 991px) {
  .header-menu-top-list {
    flex-direction: column;
  }
}

.header-menu-top-list__item:not(:last-child) {
  margin-right: 19px;
}

@media (max-width: 991px) {
  .header-menu-top-list__item:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .header-menu-top-list__item {
    margin-left: 14px;
    margin-bottom: 20px;
  }
}

.header-menu-top-list__link {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
  padding: 45px 5px;
  transition: 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .header-menu-top-list__link {
    line-height: 20px;
    padding: 0;
    text-transform: uppercase;
    color: #5C5C5C;
  }
}

.header-menu-top-list__link:hover {
  color: #EA0280;
}

.header-menu-top {
  margin-right: 35px;
  position: relative;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .header-menu-top {
    margin-top: 0;
    margin-right: 0;
  }
}

.header-menu-top .footer-menu {
  display: none;
}

@media (max-width: 991px) {
  .header-menu-top .footer-menu {
    display: block;
  }
}

.header-menu-top .trigger-close {
  display: none;
  position: absolute;
  right: 16px;
  top: 16px;
}

@media (max-width: 991px) {
  .header-menu-top .trigger-close {
    display: block;
  }
}

.header-bay {
  width: 24%;
}

@media (max-width: 991px) {
  .header-bay {
    width: auto;
  }
}

@media (max-width: 767px) {
  .header-search {
    margin-left: auto;
  }
}

.header-bottom {
  background: #FFFFFF;
  box-shadow: 0px 12px 18px rgba(92, 92, 92, 0.16);
  padding: 30px 0;
  height: 75vh;
  position: absolute;
  left: 0;
  top: 101px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s ease-in-out;
}

.header-bottom.open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.menu-bottom-list {
  overflow: auto;
  padding: 0;
}

.menu-bottom-list {
  width: 100%;
}

.menu-bottom-list__item {
  width: 14.222%;
  margin-bottom: 30px;
}

.menu-bottom-list__link {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  display: block;
  margin-bottom: 4px;
  transition: 0.3s ease-in-out;
}

.menu-bottom-list__link:hover {
  color: #EA0280;
}

.menu-bottom-list__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #000000;
}

.menu-bottom-list--big {
  width: 100%;
  margin-bottom: 80px;
}

.menu-bottom-list--big .menu-bottom-list__item {
  width: 23%;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .menu-bottom-list--big .menu-bottom-list__item {
    width: 50%;
  }
}

.search-block {
  position: relative;
  margin-right: 24px;
}

@media (max-width: 575px) {
  .search-block {
    margin-right: 20px;
  }
}

.search-block:hover .search-block__input {
  opacity: 1;
  width: 640px;
}

@media (max-width: 767px) {
  .search-block:hover .search-block__input {
    width: 320px;
  }
}

@media (max-width: 575px) {
  .search-block:hover .search-block__input {
    width: 230px;
  }
}

.search-block__btn {
  border: none;
  background: transparent;
  position: relative;
  z-index: 2;
  padding-left: 0;
  padding-right: 0;
}

.search-block__input {
  font-size: 15px;
  line-height: 22px;
  right: 0;
  color: #909090;
  transition: 0.3s ease-in-out;
  position: absolute;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #909090;
  padding-right: 29px;
  opacity: 0;
  padding-bottom: 8px;
  width: 0;
  background: #FFFFFF;
  z-index: 9;
}

.search-block__input:focus, .search-block__input:valid {
  opacity: 1;
  width: 640px;
}

@media (max-width: 767px) {
  .search-block__input:focus, .search-block__input:valid {
    width: 320px;
  }
}

@media (max-width: 575px) {
  .search-block__input:focus, .search-block__input:valid {
    width: 278px;
    height: 55px;
    top: -13px;
    line-height: 43px;
  }
}

.bay-block {
  display: flex;
  align-items: center;
}

.bay-block-action {
  position: relative;
  margin-right: 8px;
}

.bay-block-action__some {
  font-size: 10px;
  line-height: 14px;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #EA0280;
  color: #FFFFFF;
}

.bay-block-cost {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  margin-right: 15px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .bay-block-cost {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .bay-block-cost {
    display: none;
  }
}

@media (max-width: 767px) {
  .bay-block .btn {
    display: none;
  }
}

.burger {
  position: relative;
  padding: 0;
  border: none;
  align-self: center;
  background: transparent;
  cursor: pointer;
  z-index: 999;
  flex-shrink: 0;
  width: 22px;
  height: 17px;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger.active span:nth-child(1) {
  transform: translate(0, 3px) rotate(45deg);
}

.burger.active span:nth-child(2) {
  width: 0;
}

.burger.active span:nth-child(3) {
  transform: translate(0, -13px) rotate(-45deg);
}

.burger span {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  transition: 0.3s ease-in-out;
  border-radius: 25px;
  background: #5C5C5C;
}

.burger span:nth-child(1) {
  top: 0;
  left: 0;
  bottom: 0;
}

.burger span:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:nth-child(3) {
  left: 0;
  bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header .footer-menu__link {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #5C5C5C !important;
}

.header .footer-menu__item {
  margin-right: 30px;
}

@media (max-width: 991px) {
  .header .footer-menu__item {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .header .footer-menu {
    margin-left: 14px;
  }
}

.digits ul {
  background: transparent;
  position: relative;
}

.header__login {
  margin: 0 15px;
}

.header-search {
  margin-top: 7px;
}

@media (max-width: 767px) {
  .header-search {
    margin-top: 0;
  }
}

.bay-block {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .bay-block {
    margin-top: 0;
  }
}

.footer {
  border-top: 1px solid #C9C9C9;
  padding: 40px 0;
}

@media (max-width: 767px) {
  .footer {
    padding: 20px 0;
  }
}

.footer-menu {
  padding: 0;
  display: flex;
  margin-bottom: 16px;
}

.footer-menu__item:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 767px) {
  .footer-menu__item:not(:last-child) {
    margin-right: 20px;
  }
}

.footer-menu__link {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000 !important;
}

.footer__about {
  font-size: 12px;
  line-height: 14px;
  color: #5C5C5C;
}

.btn {
  font-weight: 500;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 20px;
  padding: 0 30px;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  border: none;
  background: #EA0280;
}

.btn:hover {
  color: #FFFFFF;
  background: #fd2299;
}

.btn--border {
  height: 40px;
  display: inline-flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.btn--border:hover {
  background: #EA0280;
  border-color: #EA0280;
  color: #FFFFFF;
}

.btn.disable:hover {
  background: #EA0280;
  cursor: no-drop;
}

.SlimSelect {
  max-width: 224px;
  margin-bottom: 24px;
}

.SlimSelect .ss-single-selected {
  border-radius: 20px;
  height: 40px;
  padding-left: 24px;
  padding-right: 16px;
}

.SlimSelect .placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
}

.SlimSelect .ss-main .ss-single-selected .placeholder .ss-disabled {
  color: #5C5C5C;
}

.SlimSelect .ss-content.ss-open {
  border-top: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.SlimSelect .ss-content .ss-list .ss-option.ss-highlighted, .SlimSelect .ss-content .ss-list .ss-option:hover {
  color: #EA0280;
  background-color: transparent;
}

.SlimSelect .ss-content .ss-option {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  padding-left: 24px;
}

:root {
  --swiper-theme-color:#ffff;
  --swiper-pagination-color: #ffff;
}

.main_part-slide {
  margin-bottom: 60px;
}

.slider-home-item {
  height: 520px;
  position: relative;
}

@media (max-width: 767px) {
  .slider-home-item {
    height: 400px;
  }
}

.slider-home-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-home-item__text {
  position: relative;
  z-index: 4;
  padding-top: 140px;
}

@media (max-width: 991px) {
  .slider-home-item__text {
    text-align: left;
    padding-top: 90px;
  }
}

.slider-home-item__title {
  font-weight: 600;
  max-width: 700px;
  font-size: 48px;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: 16px;
  color: #000000;
}

@media (max-width: 991px) {
  .slider-home-item__title {
    font-size: 28px;
    line-height: 36px;
    max-width: 700px;
    width: 200px;
    text-align: left;
    letter-spacing: 0.02em;
  }
}

@media (min-width: 992px) {
  .slider-home-item__title br {
    display: none;
  }
}

.slider-home-item__decr {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 64px;
}

@media (max-width: 991px) {
  .slider-home-item__decr {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: left;
    width: 154px;
    color: #000000;
  }
}

.home-slider .swiper-pagination {
  height: 14px;
}

.home-slider .home-slide-btn {
  width: auto;
  display: block;
}

.home-slider .home-slide-btn:after {
  display: none;
}

@media (max-width: 767px) {
  .home-slider .home-slide-btn {
    display: none;
  }
}

.home-slider .home-slide-btn rect {
  transition: 0.3s ease-in-out;
  fill-opacity: 0.1;
}

.home-slider .home-slide-btn:hover rect {
  fill-opacity: 0.6;
}

.home-slider .swiper-button-next {
  right: 0px;
}

.home-slider .swiper-button-prev {
  left: 0px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
}

.swiper-pagination-bullet {
  background: #C9C9C9;
  opacity: 1;
}

.hot-slider-wrapper {
  position: relative;
  margin-bottom: 80px;
  text-align: center;
}

@media (max-width: 767px) {
  .hot-slider-wrapper {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-container {
    padding-bottom: 58px;
  }
}

.hot-slider-wrapper .swiper-container .swiper-pagination-bullets-dynamic {
  height: 20px;
  display: none;
}

@media (max-width: 991px) {
  .hot-slider-wrapper .swiper-container .swiper-pagination-bullets-dynamic {
    display: block;
  }
}

.hot-slider-wrapper .swiper-button-custom {
  width: auto;
  height: auto;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .hot-slider-wrapper .swiper-button-custom {
    display: none;
  }
}

.hot-slider-wrapper .swiper-button-custom:focus {
  outline: none;
}

.hot-slider-wrapper .swiper-button-custom:after {
  display: none;
}

.hot-slider-wrapper .swiper-button-prev, .hot-slider-wrapper .swiper-container-rtl .swiper-button-next {
  left: -60px;
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-button-prev, .hot-slider-wrapper .swiper-container-rtl .swiper-button-next {
    left: 9px;
  }
}

.hot-slider-wrapper .swiper-button-next, .hot-slider-wrapper .swiper-container-rtl .swiper-button-prev {
  right: -66px;
}

@media (max-width: 767px) {
  .hot-slider-wrapper .swiper-button-next, .hot-slider-wrapper .swiper-container-rtl .swiper-button-prev {
    right: 10px;
  }
}

.hot-slider-item {
  display: block;
}

.hot-slider-item:hover .hot-slider-item__overlay {
  opacity: 1;
}

.hot-slider-item__img {
  width: 100%;
  height: 400px;
  margin-bottom: 17px;
  background: #f9f9f9;
  position: relative;
}

@media (max-width: 767px) {
  .hot-slider-item__img {
    height: 240px;
  }
}

.hot-slider-item__img img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  display: block;
  overflow: hidden;
}

.hot-slider-item__text {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  margin-bottom: 8px;
  text-align: left;
  position: relative;
  z-index: 3;
}

.hot-slider-item__price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: left;
}

.hot-slider-item .hot-slider-item__overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.64);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5C5C5C;
}

.sidebar-catalog {
  background: #F9F9F9;
  padding: 16px 8px;
}

@media (max-width: 767px) {
  .sidebar-catalog {
    position: fixed;
    left: -300%;
    top: 0;
    z-index: 9;
    display: none;
  }
}

.sidebar-catalog.active {
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  display: block;
  animation: fadeIn .3s ease-in-out;
  padding: 140px 15px 20px;
}

.sidebar-catalog__item {
  margin-bottom: 15px;
}

.sidebar-catalog__link {
  color: #000000;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 4px;
  transition: 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
}

.sidebar-catalog__link:hover {
  color: #EA0280;
}

.js__accordion {
  position: relative;
}

.js__accordion:before, .js__accordion:after {
  content: '';
  width: 12px;
  position: absolute;
  height: 1px;
  top: 10px;
  background: #909090;
  right: 6px;
  transition: 0.3s ease-in-out;
}

.js__accordion:before {
  transform: rotate(90deg);
}

.js__accordion.active:before {
  transform: rotate(0deg);
}

.sidebar-catalog-list-chilled {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  display: none;
}

.sidebar-catalog-list-chilled__item {
  margin-bottom: 4px;
}

.sidebar-catalog-list-chilled__link {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  margin-bottom: 4px;
  transition: 0.3s ease-in-out;
}

.sidebar-catalog-list-chilled__link:hover {
  color: #EA0280;
}

.sidebar-catalog__btn {
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  padding-left: 0;
  color: #000000;
  border: none;
  background: transparent;
  display: none;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .sidebar-catalog__btn {
    margin-bottom: 30px;
    display: block;
  }
}

.card {
  width: 100%;
  display: block;
}

.card:hover .card__overlay {
  opacity: 1;
}

.card__img {
  width: 100%;
  height: 400px;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  background: #f9f9f9;
}

@media (max-width: 767px) {
  .card__img {
    height: 238px;
  }
}

.card__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card__discount {
  width: 47px;
  height: 26px;
  background: #FE4C4C;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  position: absolute;
  top: 10px;
  left: 0;
}

.card__new {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 47px;
  height: 26px;
  background: #000000;
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.card__overlay {
  background: rgba(255, 255, 255, 0.64);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #5C5C5C;
  opacity: 0;
}

.card__text {
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
  margin-bottom: 8px;
  height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

@media (max-width: 575px) {
  .card__text {
    height: 45px;
  }
}

.card__price {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.tab-new-control {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 575px) {
  .tab-new-control {
    flex-direction: column;
  }
}

.tab-new-control__item:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 575px) {
  .tab-new-control__item:not(:last-child) {
    margin-right: 0;
  }
}

@media (max-width: 575px) {
  .tab-new-control__item {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.tab-new-control__link {
  font-weight: 500;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 20px;
  padding: 0 30px;
  color: #0b0b0b;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: .3s ease-in-out;
  border: 1px solid #ea0280;
}

@media (max-width: 575px) {
  .tab-new-control__link {
    width: 100%;
  }
}

.tab-new-control__link.active {
  background: #ea0280;
  color: #fff !important;
}

.tab-new-control__link:hover {
  color: #FFFFFF;
  text-decoration: none;
  background: #fd2299;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sidebar-catalog__close {
  display: none;
  position: absolute;
  right: 15px;
  top: 80px;
}

@media (max-width: 767px) {
  .sidebar-catalog__close {
    display: block;
  }
}

/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
  border: 4px solid #888;
  margin: -4px;
  /* Set this to minus the border thickness. */
  background-color: #fff;
  cursor: move;
}

/* This is for the title text. */
.cloud-zoom-title {
  font-family: Arial, Helvetica, sans-serif;
  position: absolute !important;
  background-color: #000;
  color: #fff;
  padding: 3px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  top: 0px;
}

/* cloud zoom wrapper styles */
.cloud-zoom-wrap {
  top: 0;
  z-index: 9999;
  position: relative;
}

/* This is the zoom window. */
.cloud-zoom-big {
  border: 4px solid #ccc;
  overflow: hidden;
}

/* This is the loading message. */
.cloud-zoom-loading {
  color: white;
  background: #222;
  padding: 3px;
  border: 1px solid #000;
}

.popap-card {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 30px 24px;
  display: none;
  max-width: 340px;
  width: 100%;
  background: #FFFFFF;
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  margin: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.popap-card__close {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 15px;
  opacity: .8;
  transition: 0.3s ease-in-out;
}

.popap-card__close:hover {
  opacity: 1;
}

.popap-card__img {
  margin-right: 12px;
  width: 84px;
  height: 120px;
  background: #f9f9f9;
}

.popap-card__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popap-card__title {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #363636;
  text-align: center;
}

.popap-card__name {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 14px;
}

.popap-card__cost {
  font-size: 12px;
  line-height: 18px;
  color: #5C5C5C;
}

.popap-card__bottom {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #EA0280;
  text-align: center;
  height: 60px;
  width: 200px;
}

.popap-card__back {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #EA0280;
  text-align: center;
  display: block;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popap-card__back:hover {
  color: #fd2299;
}

.popap-card__order {
  text-align: center;
  margin-bottom: 16px;
  display: block;
}

.popap-card__order a {
  width: 204px;
  height: 40px;
  text-align: center;
  justify-content: center;
}

.popap-card__row {
  display: flex;
  margin-bottom: 32px;
}

.popap-card__val {
  visibility: hidden;
}

.fancybox-bg {
  height: 100%;
  width: 100%;
}

.popap-card__cine {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  margin-top: auto;
}

.popap-card__text {
  display: flex;
  flex-direction: column;
}

@media (max-width: 575px) {
  .button_buy {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.product-slider-main {
  display: flex;
}

.product-slider-main__nav {
  width: 83px;
  height: 520px;
  margin-right: 16px;
}

@media (max-width: 575px) {
  .product-slider-main__nav {
    display: none;
  }
}

.product-slider-main__nav__item {
  width: 83px;
  height: 118px;
  background: #f9f9f9;
}

.product-slider-main__nav__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-slider-main__nav .swiper-slide {
  width: auto;
  height: auto;
}

.product-slider-main__big {
  max-width: 356px;
  width: 100%;
}

@media (max-width: 767px) {
  .product-slider-main__big {
    max-width: 100%;
  }
}

.product-slider-main__big__item {
  width: 100%;
  display: block;
  height: 520px;
  background: #f9f9f9;
}

@media (max-width: 767px) {
  .product-slider-main__big__item {
    height: 360px;
  }
}

.product-slider-main__big__item img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.product-slider-main__big .swiper-pagination {
  height: 20px;
  display: none;
}

@media (max-width: 767px) {
  .product-slider-main__big .swiper-pagination {
    display: block;
  }
}

.product-slider-main__big .swiper-btn {
  width: auto;
  height: auto;
  margin-top: auto;
  z-index: 10;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  opacity: 0.4;
}

@media (max-width: 767px) {
  .product-slider-main__big .swiper-btn {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-slider-main__big .swiper-btn svg {
    width: 25px;
  }
}

.product-slider-main__big .swiper-btn path {
  transition: 0.3s ease-in-out;
}

.product-slider-main__big .swiper-btn:hover {
  opacity: 1;
}

.product-slider-main__big .swiper-btn:hover path {
  fill-opacity: 1;
}

.product-slider-main__big .swiper-btn:after {
  content: none;
}

.product-text-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.product-now {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1AB850;
}

.product-code {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
}

.product-price {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 40px;
  display: block;
}

.product-accordion__nav {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #5C5C5C;
  position: relative;
  border-top: 1px solid #C9C9C9;
  padding: 8px 0;
  cursor: pointer;
}

.product-accordion__nav:before, .product-accordion__nav:after {
  content: '';
  width: 12px;
  position: absolute;
  top: 15px;
  right: 5px;
  background: #909090;
  height: 1px;
  transition: .5s ease-in-out;
}

.product-accordion__nav.active:after {
  transform: rotate(90deg);
}

.product-accordion__content {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
