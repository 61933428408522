.btn{
  font-weight: 500;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 20px;
  padding: 0 30px;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  transition: $trans;
  border: none;
  background: $accent;
  &:hover{
    color: #FFFFFF;
    background: lighten($accent , 10%);
  }
  &--border{
    height: 40px;
    display: inline-flex;
    background: transparent;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    &:hover{
      background: $accent;
      border-color: $accent;
      color: #FFFFFF;
    }
  }
}
.btn.disable:hover{
  background: $accent;
  cursor: no-drop;
}