


.sidebar-catalog{
  background: #F9F9F9;
  padding: 16px 8px;
  @include to(md){
    position: fixed;
    left: -300%;
    top: 0;
    z-index: 9;
    display: none;
  }
  &.active{
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
    animation: fadeIn .3s ease-in-out;
    padding: 140px 15px 20px;
  }
  &__item{
    margin-bottom: 15px;
  }
  &__link{
    color: #000000;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 4px;
    transition: $trans;
    position: relative;
    cursor: pointer;
    &:hover{
      color: $accent;
    }

  }
}
.js__accordion{
  position: relative;
  &:before,  &:after{
    content: '';
    width: 12px;
    position: absolute;
    height: 1px;
    top: 10px;
    background: #909090;
    right: 6px;
    transition: $trans;
  }
  &:before{
    transform: rotate(90deg);
  }
  &.active{
    &:before{
      transform: rotate(0deg);
    }
  }
}
.sidebar-catalog-list-chilled{
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  display: none;
  &__item{
    margin-bottom: 4px;
  }
  &__link{
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    margin-bottom: 4px;
    transition: $trans;
    &:hover{
      color: $accent;
    }
  }
}


.sidebar-catalog__btn{
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  padding-left: 0;
  color: #000000;
  border: none;
  background: transparent;
  display: none;
  margin-bottom: 40px;
  @include to(md){
    margin-bottom: 30px;
    display: block;
  }
}

.card{
  width: 100%;
  display: block;
  &:hover{
    .card__overlay{
      opacity: 1;
    }
  }
  &__img{
    width: 100%;
    height: 400px;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
    background: #f9f9f9;
    @include to(md){
      height: 238px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__discount{
    width: 47px;
    height: 26px;
    background: #FE4C4C;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    position: absolute;
    top: 10px;
    left: 0;
  }
  &__new{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 47px;
    height: 26px;
    background: #000000;
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
  }

  &__overlay{
    background: rgba(255, 255, 255, 0.64);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $trans;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #5C5C5C;
    opacity: 0;
  }
  &__text{
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    margin-bottom: 8px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    @include to(sm){
      height: 45px;
    }
  }
  &__price{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}

.tab-new-control {
  display: flex;
  margin-bottom: 20px;
  @include to(sm){
    flex-direction: column;
  }
  &__item{
    &:not(:last-child){
      margin-right: 15px;
      @include to(sm){
        margin-right: 0;
      }
    }
    @include to(sm){
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  &__link{
    font-weight: 500;
    height: 36px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 20px;
    padding: 0 30px;
    color: #0b0b0b;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-in-out;
    border: 1px solid #ea0280;
    @include to(sm){
      width: 100%;
    }
    &.active{
    background:  #ea0280;
      color: #fff !important;
    }
    &:hover{
      color: #FFFFFF;
      text-decoration: none;
      background: lighten(#ea0280, 10%);
    }
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

.sidebar-catalog__close{
  display: none;
  position: absolute;
  right: 15px;
  top: 80px;
  @include to(md){
    display: block;
  }
}


/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
  border: 4px solid #888;
  margin:-4px;	/* Set this to minus the border thickness. */
  background-color:#fff;
  cursor:move;
}

/* This is for the title text. */
.cloud-zoom-title {
  font-family:Arial, Helvetica, sans-serif;
  position:absolute !important;
  background-color:#000;
  color:#fff;
  padding:3px;
  width:100%;
  text-align:center;
  font-weight:bold;
  font-size:10px;
  top:0px;
}

/* cloud zoom wrapper styles */
.cloud-zoom-wrap {
  top:0;
  z-index:9999;
  position:relative;
}

/* This is the zoom window. */
.cloud-zoom-big {
  border:4px solid #ccc;
  overflow:hidden;
}

/* This is the loading message. */
.cloud-zoom-loading {
  color:white;
  background:#222;
  padding:3px;
  border:1px solid #000;
}
.popap-card{
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 30px 24px;
  display: none;
  max-width: 340px;
  width: 100%;
  background: #FFFFFF;
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  margin: 0 !important;
  transform: translate(-50%, -50%) !important;
  &__close{
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 15px;
    opacity: .8;
    transition: $trans;
    &:hover{
      opacity: 1;
    }
  }
  &__img{
    margin-right: 12px;
    width: 84px;
    height: 120px;
    background: #f9f9f9;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title{
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #363636;
    text-align: center;
  }
  &__name{
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 14px;
  }
  &__cost{
    font-size: 12px;
    line-height: 18px;
    color: #5C5C5C;
  }
  &__bottom{
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #EA0280;
    text-align: center;
    height: 60px;
    width: 200px;
  }
  &__back{
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: $accent;
    text-align: center;
    display: block;
    transition: $trans;
    cursor: pointer;
    &:hover{
      color: lighten($accent, 10%);
    }
  }
  &__order{
    text-align: center;
    margin-bottom: 16px;
    display: block;
    a{
      width: 204px;
      height: 40px;
      text-align: center;
      justify-content: center;
    }
  }
  &__row{
    display: flex;
    margin-bottom: 32px;
  }
  &__val{
    visibility: hidden;
  }
}

.fancybox-bg {
  height: 100%;
  width: 100%;
}

.popap-card__cine {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  margin-top: auto;
}

.popap-card__text {
  display: flex;
  flex-direction: column;
}
.button_buy{
  @include to(sm){
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}