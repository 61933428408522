@import "utils/vars";
@import "utils/breakpoint";
@import "utils/libs";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/animated";
@import "utils/tipografi";
@import "utils/spaces";

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  list-style: none;
}
a[class] {
  text-decoration: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button {
  outline: none;
}
html,
body {
  min-height: 100%;
}

html,
body {
  height: 100%;
  font-family: $default-font;
}
.container-fluid{
  max-width: $content;
}
.wrapper{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
}
.main{
  flex: 1 0 auto;
}
footer{
  flex: 0 0 auto;
}
@import "modules/modules";


