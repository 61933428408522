.product-slider-main {
  display: flex;

  &__nav {
    width: 83px;
    height: 520px;
    margin-right: 16px;
    @include to(sm) {
      display: none;
    }

    &__item {
      width: 83px;
      height: 118px;
      background: #f9f9f9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-slide {
      width: auto;
      height: auto;
    }
  }

  &__big {
    max-width: 356px;
    width: 100%;
    @include to(md) {
      max-width: 100%;
    }


    &__item {
      width: 100%;
      display: block;
      height: 520px;
      background: #f9f9f9;
      @include to(md) {
        height: 360px;
      }

      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }

    .swiper-pagination {
      height: 20px;
      display: none;
      @include to(md) {
        display: block;
      }
    }
    .swiper-btn {
      width: auto;
      height: auto;
      margin-top: auto;
      z-index: 10;
      transform: translateY(-50%);
      transition: $trans;
      opacity: 0.4;
      @include to(md) {
        display: none;
      }

      svg {
        @include to(md) {
          width: 25px;
        }
      }
      path{
        transition: $trans;
      }
      &:hover {
        opacity: 1;
        path{
          fill-opacity: 1;
        }
      }

      &:after {
        content: none;
      }
    }
  }
}


.product-text-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.product-now {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1AB850;
}

.product-code {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;
}

.product-price {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 40px;
  display: block;
}

.product-accordion {
  &__nav {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #5C5C5C;
    position: relative;
    border-top: 1px solid #C9C9C9;
    padding: 8px 0;
    cursor: pointer;

    &:before, &:after {
      content: '';
      width: 12px;
      position: absolute;
      top: 15px;
      right: 5px;
      background: #909090;
      height: 1px;
      transition: .5s ease-in-out;
    }

    &.active {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}