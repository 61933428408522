// font-weight helper:
// 100 Extra Light or Ultra Light;
// 200 Light or Thin; 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url("#{$file-path}.eot");
      src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
        font-url("#{$file-path}.woff") format("woff"), font-url("#{$file-path}.ttf") format("truetype");
    } @else {
      src: url("#{$file-path}.eot");
      src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"), url("#{$file-path}.woff") format("woff"),
        url("#{$file-path}.ttf") format("truetype");
    }
  }
}

// example

@include font-face("Gilroy", "../font/Gilroy/Gilroy-Regular", 400);
@include font-face("Gilroy", "../font/Gilroy/Gilroy-Medium", 500);
@include font-face("Gilroy", "../font/Gilroy/Gilroy-SemiBold", 600);
